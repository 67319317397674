
import axios from "axios";
import Vue from "vue";
import StaffApi from "@/api/staff";
import { mapActions, mapMutations } from "vuex";
import { ActionTypes } from "@/store/auth/action-types";
import { TreeActions } from "@/store/general/action-types";
import { SessionActionNames as SSC } from "@/models/SessionStatsConstants";
import SSA from "@/services/session-helper";
import HTTP from "@/services/HttpStatusCodes";
import { Info, Warn } from "@/services/notif-utils";
import Keycloak from "keycloak-js";

const TOKEN_NAME = "keycloak-token";
const keycloakInst = new Keycloak({
  url: "https://auth.kupiku.com:8443/",
  clientId: "js-client",
  realm: "kupiku",
});
export default Vue.extend({
  name: "Login",
  data() {
    return {
      loading: false,
      tab: "staffLogin",
      username: "staff", // TODO: remove
      password: "staff_pwd", // TODO: remove
      isPwd: true,
    };
  },

  methods: {
    ...mapMutations({
      setSessionToken: "Auth/SET_SESSION_TOKEN",
    }),
    ...mapActions({
      loginUser: "Auth/" + ActionTypes.LOGIN_USER,
      switchUniversalTokenForLocal:
        "Auth/" + ActionTypes.SWITCH_UNIVERSAL_TOKEN_FOR_LOCAL,
      init: "General/" + TreeActions.LOAD_TEMPLATE_TREE,
      shipsiteLogin: "Auth/" + ActionTypes.GET_SHIPSITE_TOKEN,
    }),
    postLogin() {
      SSA.setStatAction(SSC.START)
        .then((response: any) => {
          const sessionId = response.data.session_id;
          this.setSessionToken(sessionId);
          this.loading = false;
          this.shipsiteLogin();
          this.$router.push({ name: "Unread" });
        })
        .catch((error) => {
          this.loading = false;
          Warn("Unable to Start Session");
          return;
        });
    },
    keycloakLogin() {
      keycloakInst.login().then((authenticated: any) => {
        if (authenticated) {
          if (authenticated) {
            this.switchKeycloakToken();
          }
        }
      });
    },
    switchKeycloakToken() {
      this.loading = true;
      this.switchUniversalTokenForLocal(keycloakInst.token)
        .then(() => {
          this.init()
            .then(this.postLogin)
            .catch((err) => {
              console.error(err);
              this.loading = false;
            });
        })
        .catch((error: any) => {
          if (error.response.status == 400) {
            Info("No Such User Found.");
            this.loading = false;
          }
        });
    },
    login() {
      this.loading = true;
      const data = {
        username: this.username,
        password: this.password,
      };

      this.loginUser(data)
        .then(() => {
          StaffApi.setUpShipsiteToken().catch((error) => {
            console.log("Unable to get Shipsite Token");
          });
          this.init()
            .then(this.postLogin)
            .catch((err) => {
              console.error(err);
              this.loading = false;
            });
        })
        .catch((error) => {
          this.loading = false;
          switch (error.response.status) {
            case HTTP.SERVER_ERROR:
              this.$q.notify({
                position: "top",
                type: "negative",
                message: `Whoops! Internal Server Error`,
              });
              break;

            case HTTP.BAD_REQUEST:
              this.$q.notify({
                position: "top",
                type: "negative",
                message: `Whoops! Bad Request`,
              });
              break;

            case HTTP.NOT_FOUND:
              this.$q.notify({
                position: "top",
                type: "warning",
                message: "Invalid Credentials",
              });
              break;
          }
        });
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.username = "";
      this.password = "";
    }
    keycloakInst
      .init({
        checkLoginIframe: false,
        // enableLogging: true,
        useNonce: false,
      })
      .then((authenticated: any) => {
        console.debug(`Authenticated:${authenticated}`);
        if (authenticated) {
          this.switchKeycloakToken();
        }
      })
      .catch(function (err) {
        console.error(err);
        alert("failed to initialize keycloak");
      });
  },
});
